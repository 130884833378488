import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'

import { generateAIInteractionId } from 'modules/ai/interactionId'
import {
  CreateThemeInput,
  GeneratePptTheme,
  SlideThumbnail,
  SuggestedThemeLogo,
} from 'modules/api'
import { UploadedPptFile } from 'modules/import/types'
import { RootState } from 'modules/redux'
import {
  DEFAULT_BODY_COLOR,
  DEFAULT_ACCENT_COLOR,
  DEFAULT_HEADING_COLOR,
  DEFAULT_LIGHT_CONTAINER_BACKGROUND,
} from 'modules/theming/constants'
import {
  FontMap,
  Theme,
  ThemeColor,
  ThemeColorSolid,
} from 'modules/theming/types'
import { getThemeColorSolid } from 'modules/theming/utils/colors'
import { getThemeLogoSrc } from 'modules/theming/utils/design'
import { getEmptyTheme } from 'modules/theming/utils/emptyTheme'
import { getUpdatedThemeFonts } from 'modules/theming/utils/fonts'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'

export type ThemeImporterState = {
  theme: Theme
  fileStatus:
    | 'initial' // Pre-upload
    | 'uploading' // Upload has initated
    | 'parsing' // Parsing uploaded file with AI
    | 'review' // Review the theme knobs (user input)
  inputFile: UploadedPptFile | null
  thumbnails: SlideThumbnail[]
  themeSuggestions: GeneratePptTheme | null
  themeLogoImgStatus: 'idle' | 'loading' | 'error'
  interactionId: string
  uploadingFileId: string | null
}

const getInitialState = (): ThemeImporterState => {
  return {
    fileStatus: 'initial',
    inputFile: null,
    theme: getEmptyTheme(),
    thumbnails: [],
    themeSuggestions: null,
    themeLogoImgStatus: 'idle',
    interactionId: generateAIInteractionId(),
    uploadingFileId: null,
  }
}

const ThemeImporterSlice = createSlice({
  name: 'ThemeImporter',
  initialState: getInitialState(),
  reducers: {
    resetThemeImporterState: (state) => {
      Object.assign(state, getInitialState())
    },
    startUploadingPpt(state, action: PayloadAction<{ fileId: string }>) {
      state.fileStatus = 'uploading'
      state.uploadingFileId = action.payload.fileId
    },
    finishUploadingPpt(
      state: ThemeImporterState,
      action: PayloadAction<{
        inputFile: UploadedPptFile
      }>
    ) {
      state.inputFile = action.payload.inputFile
      // Advance to the next step: parsing
      state.fileStatus = 'parsing'
    },
    finishParsingPpt(
      state: ThemeImporterState,
      action: PayloadAction<{
        thumbnails: SlideThumbnail[]
        themeSuggestions: GeneratePptTheme
        fontsMap: FontMap
      }>
    ) {
      state.fileStatus = 'review'
      state.uploadingFileId = null
      const { themeSuggestions, thumbnails } = action.payload

      state.thumbnails = thumbnails

      // Set theme suggestions for knobs
      state.themeSuggestions = action.payload.themeSuggestions

      // Set theme to have the suggested values
      const themeToSet: Theme = {
        ...state.theme,
        name: t({
          message: `Imported from ${state.inputFile?.filename}`,
          comment: 'The name of a visual theme imported by the user',
        }),
        headingFont: themeSuggestions.headingFont.suggested,
        headingFontWeight: themeSuggestions.headingFontWeight,
        bodyFont: themeSuggestions.bodyFont.suggested,
        bodyFontWeight: themeSuggestions.bodyFontWeight,
        config: {
          ...state.theme?.config,
          primaryColor: {
            color: themeSuggestions.accentColors?.[0] || DEFAULT_ACCENT_COLOR,
            type: 'solid',
          },
          headingColor: {
            color: themeSuggestions.headingColors?.[0] || DEFAULT_HEADING_COLOR,
            type: 'solid',
          },
          bodyColor: {
            color: themeSuggestions.bodyColors?.[0] || DEFAULT_BODY_COLOR,
            type: 'solid',
          },
          cardColor: {
            color:
              themeSuggestions.cardColors?.[0] ||
              DEFAULT_LIGHT_CONTAINER_BACKGROUND,
            type: 'solid',
          },
          disableReadabilityAdjustment: false,
          background: {
            type: BackgroundType.COLOR,
            color: {
              name: themeSuggestions.backgroundColors?.[0],
              hex: themeSuggestions.backgroundColors?.[0],
            },
          },
          ...(themeSuggestions.logos.length > 0
            ? {
                logoImage: {
                  src: themeSuggestions.logos[0].url,
                  meta: {
                    width: themeSuggestions.logos[0].width,
                    height: themeSuggestions.logos[0].height,
                  },
                },
              }
            : {}),
          ...(themeSuggestions.accentColors.length > 1
            ? {
                secondaryColors: themeSuggestions.accentColors
                  .slice(1)
                  .map((color) => ({
                    color,
                    type: 'solid',
                  })),
              }
            : {}),
        },
        fonts: getUpdatedThemeFonts({
          fontsMap: action.payload.fontsMap,
          headingFontId: themeSuggestions.headingFont.suggested,
          bodyFontId: themeSuggestions.bodyFont.suggested,
        }),
      }
      state.theme = themeToSet
    },
    updateThemeName(
      state: ThemeImporterState,
      action: PayloadAction<{ name: string }>
    ) {
      state.theme.name = action.payload.name
    },
    updateCardColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.cardColor = action.payload.color
    },
    updateDocBackgroundColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.background = {
        type: BackgroundType.COLOR,
        color: { hex: getThemeColorSolid(action.payload.color) },
      }
    },
    updatePrimaryColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.primaryColor = action.payload.color
    },
    updateHeadingFont(
      state: ThemeImporterState,
      action: PayloadAction<{
        font: string
        fontsMap: FontMap
      }>
    ) {
      const { font, fontsMap } = action.payload
      state.theme.headingFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        headingFontId: font,
        bodyFontId: state.theme.bodyFont,
      })
    },
    updateBodyFont(
      state: ThemeImporterState,
      action: PayloadAction<{ font: string; fontsMap: FontMap }>
    ) {
      const { fontsMap, font } = action.payload
      state.theme.bodyFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        bodyFontId: font,
        headingFontId: state.theme.headingFont,
      })
    },
    updateHeadingFontWeight(
      state: ThemeImporterState,
      action: PayloadAction<{ weight: Theme['headingFontWeight'] }>
    ) {
      state.theme.headingFontWeight = action.payload.weight
    },
    updateBodyFontWeight(
      state: ThemeImporterState,
      action: PayloadAction<{ weight: Theme['bodyFontWeight'] }>
    ) {
      state.theme.bodyFontWeight = action.payload.weight
    },
    updateBodyColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.bodyColor = action.payload.color
    },
    updateHeadingColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColor }>
    ) {
      state.theme.config.headingColor = action.payload.color
    },
    handleLogoImageUploadStart(state: ThemeImporterState) {
      state.themeLogoImgStatus = 'loading'
    },
    handleLogoImageUploadSuccess(
      state: ThemeImporterState,
      action: PayloadAction<{ logoImage: Theme['config']['logoImage'] }>
    ) {
      if (!state.theme) return
      state.themeLogoImgStatus = 'idle'
      state.theme.logoUrl = action.payload.logoImage?.src || undefined
      state.theme.config.logoImage = action.payload.logoImage
    },

    handleSelectLogoSuggestion(
      state: ThemeImporterState,
      action: PayloadAction<{ logo: SuggestedThemeLogo }>
    ) {
      if (!state.theme) return
      state.theme.logoUrl = action.payload.logo.url
      state.theme.config.logoImage = {
        src: action.payload.logo.url,
        meta: {
          width: action.payload.logo.width,
          height: action.payload.logo.height,
        },
      }
    },

    handleLogoImageUploadError(state: ThemeImporterState) {
      state.themeLogoImgStatus = 'error'
    },
    handleRemoveLogoImage(state: ThemeImporterState) {
      if (!state.theme) return
      state.theme.logoUrl = undefined
      state.theme.config.logoImage = undefined
    },
  },
})

export const {
  resetThemeImporterState,
  finishUploadingPpt,
  startUploadingPpt,
  finishParsingPpt,
  updateThemeName,
  updateCardColor,
  updateDocBackgroundColor,
  updatePrimaryColor,
  updateHeadingFont,
  updateBodyFont,
  updateHeadingFontWeight,
  updateBodyFontWeight,
  updateBodyColor,
  updateHeadingColor,
  handleLogoImageUploadStart,
  handleLogoImageUploadSuccess,
  handleLogoImageUploadError,
  handleRemoveLogoImage,
  handleSelectLogoSuggestion,
} = ThemeImporterSlice.actions

type ThemeImporterSliceState = Pick<RootState, 'ThemeImporter'>

// Selectors
export const selectThemeImporterTheme = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.theme

export const selectThemeImporterCurrentStep = (
  state: ThemeImporterSliceState
): 'initialStep' | 'uploadStep' | 'reviewStep' => {
  if (state.ThemeImporter.fileStatus === 'initial') {
    return 'initialStep'
  }
  if (
    state.ThemeImporter.fileStatus === 'uploading' ||
    state.ThemeImporter.fileStatus === 'parsing'
  ) {
    return 'uploadStep'
  }
  return 'reviewStep'
}

export const selectThemeImporterInputFile = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.inputFile

export const selectThemeImporterFileStatus = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.fileStatus

export const selectSuggestedAccentColors = (
  state: ThemeImporterSliceState
): ThemeColorSolid[] => {
  const accentColors = state.ThemeImporter.themeSuggestions?.accentColors
  return accentColors
    ? accentColors.map((color) => ({
        color,
        type: 'solid',
      }))
    : []
}

export const selectSuggestedHeadingColors = (
  state: ThemeImporterSliceState
): ThemeColorSolid[] => {
  const headingColors = state.ThemeImporter.themeSuggestions?.headingColors
  return headingColors
    ? headingColors.map((color) => ({
        color,
        type: 'solid',
      }))
    : []
}

export const selectSuggestedBodyColors = (
  state: ThemeImporterSliceState
): ThemeColorSolid[] => {
  const bodyColors = state.ThemeImporter.themeSuggestions?.bodyColors
  return bodyColors
    ? bodyColors.map((color) => ({
        color,
        type: 'solid',
      }))
    : []
}

export const selectSuggestedCardColors = (
  state: ThemeImporterSliceState
): ThemeColorSolid[] => {
  const defaultColors = ['#FFFFFF', '#000000']
  const cardColors = state.ThemeImporter.themeSuggestions?.cardColors || []
  const allColors = uniq(
    [...defaultColors, ...cardColors].map((s) => s.toUpperCase())
  )
  return allColors.map((color) => ({
    color,
    type: 'solid',
  }))
}

export const selectSuggestedDocBackgroundColors = (
  state: ThemeImporterSliceState
): ThemeColorSolid[] => {
  const defaultColors = ['#FFFFFF', '#000000']
  const colors = state.ThemeImporter.themeSuggestions?.backgroundColors || []
  const allColors = uniq(
    [...defaultColors, ...colors].map((s) => s.toUpperCase())
  )
  return allColors.map((color) => ({
    color,
    type: 'solid',
  }))
}

export const selectFontSuggestionsAndOriginals = (
  state: ThemeImporterSliceState
) => {
  return {
    suggestedBodyFont: state.ThemeImporter.themeSuggestions?.bodyFont,
    suggestedHeadingFont: state.ThemeImporter.themeSuggestions?.headingFont,
  }
}

export const selectThemeImporterLogoSrc = (state: ThemeImporterSliceState) =>
  getThemeLogoSrc(state.ThemeImporter.theme)

export const selectSuggestedLogos = (
  state: ThemeImporterSliceState
): SuggestedThemeLogo[] => state.ThemeImporter.themeSuggestions?.logos || []

export const selectAnyThemeImagesUploading = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.themeLogoImgStatus === 'loading'

export const selectThemeImporterCreateThemeInput =
  (workspaceId: string) =>
  (state: ThemeImporterSliceState): CreateThemeInput => {
    return {
      workspaceId,
      name: state.ThemeImporter.theme.name,
      headingFont: state.ThemeImporter.theme.headingFont,
      headingFontWeight: state.ThemeImporter.theme.headingFontWeight,
      bodyFont: state.ThemeImporter.theme.bodyFont,
      bodyFontWeight: state.ThemeImporter.theme.bodyFontWeight,
      config: state.ThemeImporter.theme.config,
      priority: state.ThemeImporter.theme.priority,
    }
  }

export const selectIsThemeImportInProgress = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.fileStatus === 'uploading' ||
  state.ThemeImporter.fileStatus === 'parsing' ||
  state.ThemeImporter.fileStatus === 'review'

export const selectThemeImporterInteractionId = (
  state: ThemeImporterSliceState
) => state.ThemeImporter.interactionId

export const selectThemeImporterThumbnails = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.thumbnails

export const selectThemeImporterUploadingFileId = (
  state: ThemeImporterSliceState
) => state.ThemeImporter.uploadingFileId

export const ThemeImporterReducer = ThemeImporterSlice.reducer
