/**
 * Handles Google logins and signups only.
 * Does NOT handle user/password logins and signups.
 */
import { config } from 'config'
import {
  getApolloClient,
  UpdateUserDocument,
  UpdateUserSettingsDocument,
} from 'modules/api'
import { sendGTMSignupCompleteEvent } from 'modules/googleTagManager/events'
import {
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
  isNavigatorLanguageGA,
} from 'modules/i18n/utils/localeKey'
import { getUserMetadataStore } from 'modules/performance/UserMetadataStore'
import { analytics, SegmentEvents } from 'modules/segment'
import { generateSettingsUpdatePatch } from 'modules/user/utils'

const DEFAULT_REDIRECT_PATH_FOR_NEW_USER_WITH_NEW_WORKSPACE =
  '/welcome?newWorkspace=true'

export type SignupData = {
  // A user just signed up, and this is that user's FIRST workspace.
  // They could have joined an existing workspace. It is possible for
  // this value to be true, and the newWorkspace value to be false.
  isFirstWorkspace: boolean
  // A new workspace was created
  newWorkspace: boolean
  newUser: boolean
  signupFlow: string
  welcomeDocId?: string
  userId: string
  workspaceId: string
  extra?: {
    googleMethod?: 'ONE_TAP' | 'POPUP'
  }
}

/**
 * If the user just signed up and created a new workspace, we redirect them to the welcome page.
 * If the user just signed up and joined an existing workspace, we redirect them to the home page.
 */
const getDefaultRedirectForFirstWorkspace = ({
  data,
  redirectTo,
}: {
  data?: SignupData
  redirectTo?: string
}) => {
  let defaultRedirect = redirectTo || '/'

  if (data) {
    const { newWorkspace } = data

    // This is a new workspace, and we should give them the chance to set the workspace name
    if (newWorkspace) {
      defaultRedirect = DEFAULT_REDIRECT_PATH_FOR_NEW_USER_WITH_NEW_WORKSPACE
    }
  }
  return defaultRedirect
}

const identifyLoggedinUser = (data?: SignupData) => {
  if (!data) return
  const { userId, workspaceId } = data
  analytics.identify(userId, {
    user_org_id: workspaceId,
    anonymous_user: false,
    gamma_user_id: userId,
  })
}

const setUserReferrer = (id: string, referrer: string) => {
  return getApolloClient().mutate({
    mutation: UpdateUserDocument,
    variables: {
      input: {
        id,
        referrer,
      },
    },
  })
}

const setUserLocale = () => {
  const locale = getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback()
  const settings = {
    set: {
      locale,
    },
  }
  const patch = generateSettingsUpdatePatch(settings)

  return getApolloClient().mutate({
    mutation: UpdateUserSettingsDocument,
    variables: {
      patch,
    },
  })
}

export const handleLoginComplete = ({
  redirectTo,
  data,
  doneCallback,
  shouldClientSideRedirect = false,
  googleMethod,
}: {
  redirectTo?: string
  data?: SignupData
  doneCallback?: () => void
  shouldClientSideRedirect?: boolean
  googleMethod: 'ONE_TAP' | 'POPUP'
}) => {
  const userMetadataStore = getUserMetadataStore()
  const context = userMetadataStore.get()

  const defaultRedirect = redirectTo ? redirectTo : '/'

  identifyLoggedinUser(data)

  analytics.track(
    SegmentEvents.LOGIN,
    {
      provider: 'google',
      extra: {
        googleMethod,
      },
    },
    { context }
  )
  if (!redirectTo && doneCallback) {
    doneCallback()
    return
  }

  if (shouldClientSideRedirect) {
    window.location.href = defaultRedirect
  }
}

export const handleSignupComplete = ({
  data,
  doneCallback,
  redirectTo,
  shouldClientSideRedirect = false,
  googleMethod,
}: {
  data?: SignupData
  doneCallback?: () => void
  redirectTo?: string
  shouldClientSideRedirect?: boolean
  googleMethod: 'ONE_TAP' | 'POPUP'
}) => {
  const userMetadataStore = getUserMetadataStore()
  const context = userMetadataStore.get()
  identifyLoggedinUser(data)

  const trackPromise = new Promise((resolve) => {
    analytics.track(
      SegmentEvents.SIGNUP,
      {
        flow: data?.signupFlow,
        provider: 'google',
        extra: {
          googleMethod,
        },
      },
      { context },
      resolve
    )
  })

  const userId = data?.userId
  const setReferrerPromise =
    userId && config.IS_USER_TESTING_SESSION
      ? setUserReferrer(userId, 'USER_TESTING')
      : Promise.resolve()

  if (userId && isNavigatorLanguageGA()) {
    /// Only set locale override if it's a GA language
    setUserLocale()
  }

  let defaultRedirect = redirectTo ? redirectTo : '/'

  const isFirstWorkspaceForUser = data?.isFirstWorkspace

  // If a user already has a workspace, and is joining another one, or,
  // if for some reason we explicitly set `isFirstWorkspace` to false in
  // signup-flow.ts, as in the case of `DocInvitationSignupFlow` and
  // `DocAccessLinkSignupFlow`, then we don't want to redirect them
  if (!redirectTo && doneCallback && !isFirstWorkspaceForUser) {
    doneCallback()
    return
  }

  if (data?.newUser) {
    sendGTMSignupCompleteEvent()
  }

  // If this is the user's first workspace
  if (isFirstWorkspaceForUser && data) {
    if (doneCallback) {
      doneCallback()
      return
    }
    defaultRedirect = getDefaultRedirectForFirstWorkspace({
      data: data,
      redirectTo,
    })
  }

  Promise.all([setReferrerPromise, trackPromise]).then(() => {
    if (shouldClientSideRedirect) {
      window.location.href = defaultRedirect
    }
  })
}
