import { Editor } from '@tiptap/core'

import { DocGenerateInfo, DocumentAttributes } from './attributes'
import { DocFormat, DocFormats } from './DocFormats'

export const DEFAULT_DOC_TYPE = 'deck'

export const getDocAttrs = (editor: Editor): DocumentAttributes => {
  return editor.state.doc.firstChild!.attrs as DocumentAttributes
}

export const getDocFormatFromAttrs = (attrs: DocumentAttributes): DocFormat => {
  const docType = attrs.format || DEFAULT_DOC_TYPE
  if (docType !== 'social') {
    return findDocFormat(docType)
  }
  return DocFormats[0]
}

export const findDocFormat = (key: DocFormat['key']): DocFormat => {
  return DocFormats.find((type) => type.key === key) || DocFormats[0]
}

export const getDocGenerateInfo = (editor: Editor): DocGenerateInfo => {
  return getDocAttrs(editor).generateInfo
}
