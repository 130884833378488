import {
  faAlignLeft,
  faBrowser,
  faExpandWide,
  faFileLines,
  faMobile,
  faRectangle,
  faRectangleHistory,
  faRectangleVertical,
  faRectangleWide,
  faSquare,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'
import { JSONContent } from '@tiptap/core'

import { DocSettings } from './attributes'

export type DocFormatKey = 'deck' | 'webpage' | 'document' | 'social'

export type StyleTemplateKey =
  | 'deck_default'
  | 'deck_traditional'
  | 'deck_tall'
  | 'document_default'
  | 'document_pageless'
  | 'document_letter'
  | 'document_a4'
  | 'social_square'
  | 'social_portrait'
  | 'social_stories'
  | 'webpage_fluid'
  | 'custom'

export type StyleData = {
  label: () => string
  formatType: DocFormatKey
  cardDimensionsDesc: () => string
  recommendedFor: () => string
  icon: FontAwesomeIconProps['icon']
  docSettings: {
    cardDimensions: DocSettings['cardDimensions']
    verticalAlign: DocSettings['verticalAlign']
    defaultFullBleed: DocSettings['defaultFullBleed']
    defaultContentWidth: DocSettings['defaultContentWidth']
    fontSize?: DocSettings['fontSize']
  }
}

type PageStyleMap = {
  [key in Exclude<StyleTemplateKey, 'custom'>]: StyleData
}

export const STYLE_TEMPLATES: PageStyleMap = {
  deck_default: {
    label: () => t`Default`,
    formatType: 'deck',
    cardDimensionsDesc: () => t`Fluid`,
    recommendedFor: () =>
      `Flexible cards that expand with your content. Great great for informal presentations.`,
    icon: faExpandWide,
    docSettings: {
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'lg',
    },
  },
  deck_traditional: {
    label: () => t`Traditional`,
    formatType: 'deck',
    cardDimensionsDesc: () => '16:9',
    recommendedFor: () =>
      `Good for exporting to PowerPoint, Google Slides, Keynote`,
    icon: faRectangleWide,
    docSettings: {
      cardDimensions: '16x9',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'lg',
    },
  },
  deck_tall: {
    label: () => t`Tall`,
    formatType: 'deck',
    cardDimensionsDesc: () => '4:3',
    recommendedFor: () =>
      `Good for text-heavy presentations, and more traditional slides`,
    icon: faRectangle,
    docSettings: {
      cardDimensions: '4:3',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'lg',
    },
  },

  document_default: {
    label: () => t`Default`,
    formatType: 'document',
    cardDimensionsDesc: () => t`Fluid`,
    recommendedFor: () => t`Good for online docs and content`,
    icon: faExpandWide,
    docSettings: {
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'md',
    },
  },
  document_pageless: {
    label: () => t`Pageless`,
    formatType: 'document',
    recommendedFor: () => t`Good for articles and posts`,
    cardDimensionsDesc: () => t`Fluid`,
    icon: faAlignLeft,
    docSettings: {
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultFullBleed: 'full',
      defaultContentWidth: 'md',
    },
  },
  document_letter: {
    label: () => t`Letter`,
    formatType: 'document',
    recommendedFor: () => t`Good for ebooks, printing and exporting`,
    cardDimensionsDesc: () => t`US letter`,
    icon: faRectangleVertical,
    docSettings: {
      cardDimensions: 'letter_portrait',
      verticalAlign: 'start',
      defaultFullBleed: 'contained',
      defaultContentWidth: 'md',
    },
  },
  document_a4: {
    label: () => t`A4`,
    formatType: 'document',
    recommendedFor: () => t`Good for ebooks, printing and exporting`,
    cardDimensionsDesc: () => t`A4`,
    icon: faRectangleVertical,
    docSettings: {
      cardDimensions: 'a4_portrait',
      defaultFullBleed: 'contained',
      verticalAlign: 'start',
      defaultContentWidth: 'md',
    },
  },
  social_square: {
    label: () => t`Square`,
    formatType: 'social',
    recommendedFor: () => t`Good for Instagram and social posts`,
    icon: faSquare,
    cardDimensionsDesc: () => '1:1',
    docSettings: {
      cardDimensions: 'square',
      verticalAlign: 'center',
      defaultContentWidth: 'sm',
      defaultFullBleed: 'contained',
      fontSize: 'lg',
    },
  },
  social_portrait: {
    label: () => t`Portrait`,
    formatType: 'social',
    recommendedFor: () => t`Good for Instagram posts and Linkedin carousels`,
    icon: faRectangleVertical,
    cardDimensionsDesc: () => '5:4',
    docSettings: {
      cardDimensions: '5:4',
      verticalAlign: 'center',
      defaultContentWidth: 'sm',
      defaultFullBleed: 'contained',
      fontSize: 'lg',
    },
  },
  social_stories: {
    label: () => t`Stories`,
    formatType: 'social',
    recommendedFor: () => t`Good for Instagram stories and TikTok reels`,
    icon: faMobile,
    cardDimensionsDesc: () => '9:16',
    docSettings: {
      cardDimensions: '16x9_portrait',
      verticalAlign: 'center',
      defaultContentWidth: 'sm',
      defaultFullBleed: 'contained',
      fontSize: 'lg',
    },
  },
  webpage_fluid: {
    label: () => t`Webpage`,
    formatType: 'webpage',
    recommendedFor: () => t`Good for websites and landing pages`,
    cardDimensionsDesc: () => t`Fluid`,
    icon: faBrowser,
    docSettings: {
      cardDimensions: 'fluid',
      verticalAlign: 'center',
      defaultContentWidth: 'lg',
      defaultFullBleed: 'full',
    },
  },
}

export type DocFormat = {
  key: Exclude<DocFormatKey, 'social'>
  label: string
  generationLabel: string // The label we pass to AI prompts
  cardLabel: string
  defaultDocContent?: JSONContent[]
  fontSize: number
  contentWidth: number // Characters per line
  primaryAction?: 'present' // Todo: add share, preview/publish, etc
}

export const DocFormats: DocFormat[] = [
  {
    key: 'deck',
    label: 'presentation',
    generationLabel: 'presentation',
    cardLabel: 'slide',
    contentWidth: 95,
    fontSize: 1.25,
    primaryAction: 'present',
  },
  {
    key: 'webpage',
    label: 'page',
    cardLabel: 'section',
    generationLabel: 'webpage',
    contentWidth: 105,
    fontSize: 1.125,
  },
  {
    key: 'document',
    label: 'document',
    cardLabel: 'section',
    generationLabel: 'document',
    contentWidth: 85,
    fontSize: 1,
  },
]

type DocFormatCategories = {
  [formatType in DocFormatKey]: {
    name: () => string
    formatType: DocFormatKey
    description: () => string
    icon: FontAwesomeIconProps['icon']
    styleTemplates: StyleTemplateKey[]
  }
}

function findMatchingPageStyleKey(format: DocFormatKey): StyleTemplateKey[] {
  return (Object.keys(STYLE_TEMPLATES) as StyleTemplateKey[]).filter(
    (styleKey: StyleTemplateKey) => {
      const style = STYLE_TEMPLATES[styleKey]
      return style.formatType === format
    }
  )
}

export const DOC_FORMAT_CATEGORIES: DocFormatCategories = {
  deck: {
    name: () => t`Presentation`,
    formatType: 'deck',
    description: () =>
      t`A slide deck. Good for live presentations or shareable decks.`,
    icon: faRectangleHistory,
    styleTemplates: findMatchingPageStyleKey('deck'),
  },
  document: {
    name: () => t`Document`,
    formatType: 'document',
    description: () =>
      t`Pageless or US Letter/A4 sized cards. Good for ebooks, documents and printing.`,
    icon: faFileLines,
    styleTemplates: findMatchingPageStyleKey('document'),
  },
  social: {
    name: () => t`Social media`,
    formatType: 'social',
    description: () => t`Smaller cards, good for sharing on social`,
    icon: faMobile,
    styleTemplates: findMatchingPageStyleKey('social'),
  },
  webpage: {
    name: () => t`Webpage`,
    formatType: 'webpage',
    description: () =>
      t`Fluid height sections, with a navbar. Publish to custom domain`,
    icon: faBrowser,
    styleTemplates: findMatchingPageStyleKey('webpage'),
  },
}

export type FontSize = 'sm' | 'md' | 'lg'
